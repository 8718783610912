<template>
    <v-file-input
            v-model="files"
            :label="label"
            @change="updateValue"
            outlined
            show-size
            counter
            multiple
    ></v-file-input>
</template>

<script>
    export default {
        name: 'File',
        data: () => ({
            files: [],
        }),
        props: {
            label: {
                required: false
            }
        },
        methods: {
            updateValue() {
                this.$emit("addFiles", this.files);
            }
        },
    };
</script>
