<template>
    <div class="login">
        <div v-if="isLoading">
          <Loader />
        </div>

        <v-container fluid class="vh-100">
            <v-row>
                <v-col class="mt-15 mb-2 mb-md-5 text-center">
                    <router-link to="/">
                        <i class="edt-logo fw-25x text-black"></i>
                    </router-link>
                </v-col>
            </v-row>
            <v-row class="mb-3">
                <v-col class="text-center">
                    <h1><span class="d-block">{{ $t("login.title1") }}</span><span class="d-block">{{ $t("login.title2") }}</span></h1>
                </v-col>
            </v-row>
            <div>
                <v-row v-if="showError">
                    <v-col cols="12" sm="6" class="error-box mx-auto">
                        <v-alert type="error">
                            {{ $t(errorMessage) }}
                        </v-alert>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" sm="6"  class="mx-auto">
                        <v-text-field
                          :label="$t('email')"
                          name="username"
                          v-model="form.username"
                          autocapitalize="none"
                          spellcheck="false"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" sm="6" class="mx-auto p-relative">
                        <v-text-field
                          :label="$t('password')"
                          name="password"
                          v-model="form.password"
                          type="password"
                          class="hide-text-field"
                        ></v-text-field>
                        <router-link class="link-forgot-password font-weight-bold text-body-2" to="/forgotten_password" v-if="printLoginTools">{{ $t("forgottenPassword") }}</router-link>
                    </v-col>
                </v-row>
            </div>
            <v-row class="align-content-end">
                <v-col class="text-center">
                    <v-btn
                        class="primary mt-8"
                        @click="submit()"
                    >{{ $t("connection") }}</v-btn>
                    <div class="font-weight-bold text-body-2 mt-5" v-if="printLoginTools">
                        {{ $t("textLinkRegister") }}
                        <router-link to="/contact">{{ $t("linkContact") }}</router-link>
                    </div>
                    <div class="mb-7 opacity-50">v{{ this.version }}</div>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>
import { mapActions } from "vuex";
import Loader from "../../components/Loader";

export default {
  name: "Login",
  components: { Loader },
  data() {
    return {
      form: {
        username: "",
        password: "",
      },
      showError: false,
      errorMessage: "",
      isLoading: false,
      version: "",
      printLoginTools: false
    };
  },
  created() {
    this.Version();
    this.version = this.$store.getters.version;
    if (this.$store.getters.StateUserData) {
      this.$router.push("/");
    }
  },
    computed: {
    },
  methods: {
    ...mapActions([
        "LogIn",
        "GetUserData",
        "GetInterventions",
        "GetRealEstatesWithContract",
        "GetProviders",
        "GetMessagingCount",
        "GetOwnerDashboardOS",
        "GetRealEstates",
        "GetTypes",
        "Version",
        "LoadMeDetails"
    ]),
    async submit() {
      if (window.cordova && navigator.connection.type == "none") {
        this.showError = true;
        this.errorMessage = "No network available";

        setTimeout(function () {
          navigator.app.exitApp();
        }, 2000);
      } else {
        this.showError = false;
        const User = {
          email: this.form.username,
          password: this.form.password,
        };
        this.isLoading = true;
        try {
          await this.LogIn(User);
          await this.GetUserData();
          await this.GetMessagingCount();
          if (this.$store.getters.isManager) {
            this.GetRealEstatesWithContract()
          }
            if (this.$store.getters.hasOS) {
                this.GetOwnerDashboardOS().then(() => {
                    if(this.$store.getters.StateDashboardOS.canCreateWorkValidationRequest) {
                        this.GetProviders();
                        this.GetRealEstates();
                        this.GetTypes();
                    }
                });
            }
          localStorage.removeItem('hideBoarding');
            if (this.$store.getters.hasEchopark) {
                await this.LoadMeDetails();
            }
            this.$router.push(this.initialUrlAfterLogin);
        } catch (error) {
          this.errorMessage = 'invalidParameters';
          this.showError = true;
        }
        this.isLoading = false;
      }
    },
  },
};
</script>
