<template>
    <div class="d-flex align-items-center w-100 me-2">
        <v-progress-linear
        :value="progressionCalcul"
        :color="progressionColor()"
        height="25"
        >
            {{ progressionCalcul }}%
        </v-progress-linear>
    </div>
</template>

<script>
    import { mapGetters } from "vuex";
    export default {
        name: 'Progression',
        props: {
            formId: {
                type: String,
                required: false
            },
            visitId: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                progressionValue: 0
            };
        },
        computed: {
            ...mapGetters( 'visitStore', {progression: 'StateVisitsFormsProgression' } ),
            progressionCalcul() {
                let complete = 0;
                let total = 0;
                
                if (!this.progression || !this.progression[this.visitId]) {
                    return 0; // Pas encore de données disponibles
                }
                
                if(this.formId != null) {
                    if(this.progression[this.visitId][this.formId].complete !== 'undefined' && this.progression[this.visitId][this.formId].total !== 'undefined' && Number.isInteger(this.progression[this.visitId][this.formId].complete) && Number.isInteger(this.progression[this.visitId][this.formId].total)) {
                        complete = this.progression[this.visitId][this.formId].complete;
                        total = this.progression[this.visitId][this.formId].total;
                    }
                } else {
                    Object.values(this.progression[this.visitId]).forEach(elementForm => {
                        if(elementForm.complete !== 'undefined' && elementForm.total !== 'undefined' && Number.isInteger(elementForm.complete) && Number.isInteger(elementForm.total)) {
                            complete += elementForm.complete;
                            total += elementForm.total;
                        }
                    });
                }
                let pourcentage = total > 0 ? Math.round(complete*100/total) : 0;
                return parseInt(pourcentage);
            },
        },
        methods: {            
            progressionColor() {
                let classColor = 'success';
                if(this.progressionCalcul < 60) {
                    classColor = this.progressionCalcul > 40 ? 'primary' : 'error';
                }
                return classColor;
            }
        },
        watch: {
            progression: {
                handler() {
                    this.progressionCalcul;
                },
                deep: true, // Suivre les changements dans un objet profond
                immediate: true // Exécuter au montage
            }
        },
        created() {
            //this.progressionCalcul();
        }
    };
</script>
