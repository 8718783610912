<template>
    <div v-if="visitInfos" class="text-center">
        <span v-if="!isStarted && isMine && !getData" class="btn-round-big bg-blue mx-2" @click="downloadFiles()"><span v-if="this.totalProgress != null" class="pourcentage-download">{{ totalProgress }}%</span><v-icon v-else>mdi-clipboard-arrow-down-outline</v-icon></span>
        <span v-if="!isStarted && isMine && getData" class="btn-round-big bg-green mx-2" @click="startVisit()"><v-icon>mdi-play</v-icon></span>
        <span v-if="parseInt(this.currentStartedVisit) === parseInt(this.$route.params.id)" class="btn-round-big btn-primary mx-2" @click="sendVisit('draft')"><v-icon>mdi-pause</v-icon></span>     
        <span v-if="parseInt(this.currentStartedVisit) === parseInt(this.$route.params.id)" class="btn-round-big bg-alert mx-2" @click="sendVisit('terminate')"><v-icon>mdi-stop</v-icon></span>
        <span v-if="!isMine" class="btn-round-big bg-blue mx-2" @click="assignVisit()"><v-icon>mdi-account-arrow-left-outline</v-icon></span>
        <PopinFiles v-if="isMine && haveFiles" />
    </div>
</template>

<script>
    import { mapGetters, mapActions } from "vuex";
    import PopinFiles from '@/components/visit/detail/PopinFiles.vue';
    export default {
        name: 'VisitActions',
        components: { PopinFiles },
        computed: {
            ...mapGetters( 'visitStore', {currentStartedVisit: 'StateCurrentStartedVisit', visitInfos: 'StateVisitInfos', totalProgress: 'StateTotalProgressDownload'} ),
        
            isStarted() {
                if(parseInt(this.currentStartedVisit) === parseInt(this.$route.params.id)) {
                    return true;
                }
                return false;
            },
            isMine() {
                if(parseInt(this.$store.getters.StateUserData.id) === parseInt(this.visitInfos.visit_assigned_to) && this.visitInfos.visit_status != "start") {
                    return true;
                }
                return false;
            },
            haveFiles() {
                const jsonFilesToSend = localStorage.getItem('files-to-send-visit-' + parseInt(this.$route.params.id));
                const filesToSend = jsonFilesToSend ? JSON.parse(jsonFilesToSend) : {};
                
                return Object.keys(filesToSend).length > 0 ? true : false;
            },
        },
        data() {
            return {
                isDownloading: false,
            };
        },
        methods: {
            ...mapActions('visitStore',["StartVisit", "SendVisit", "AssignVisit", "GetVisitForms", "GetListFiles"]),
            /* Les éléments relatifs à la visite sont enregistrés dans le localStorage, le status de la visit passe à "start" en bdd */
            /* L'utilisateur s'assigne la visite */
            assignVisit() {
                this.AssignVisit([this.$route.params.id]);
            },
            startVisit() {
                this.StartVisit(this.$route.params.id);
            },
            /* Todo : Le json est renvoyé à Sf et alimente les tables. Le status de la visite est MAj (pause/draft, stop/finish)*/
            sendVisit(state) {
                const data = this.$route.params.data == 'true' ? this.$route.params.data : false;
                this.SendVisit([this.$route.params.id, state]).then(() => {
                    this.GetVisitForms({id: this.$route.params.id, data: data}).then((res) => {
                        this.visit = res;
                    });
                });
            },
            getData() {
                const data = this.$route.params.data == 'true' ? this.$route.params.data : false;
                if (data == 'true' && (localStorage.getItem('dl-' + this.$route.params.id) === null || localStorage.getItem('dl-' + this.$route.params.id) == false)) {
                    return false;
                }
                return true;
            },
            async downloadFiles() {               
                this.isDownloading = true;
                await this.GetListFiles({id: this.$route.params.id}).then(() => { this.getData(); }); 
                this.isDownloading = false;
            },
        },
        created() {
        }
    };
</script>
