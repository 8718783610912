<template>
    <div v-if="field" :class="field.field_type != 'title' ? 'mb-4' : 'mb-3 field-title'">
        <div class="d-flex align-items-center" :class="checkVisibility()">
            <div class="swipe-container w-100">
                <div class="swipe-action swipe-left swipe">
                    <i class="edt-eye"></i>
                </div>

                <div class="swipe-element"
                    v-touch="{
                        left: () => hideItem(),
                        right: () => showItem()
                    }">
                    <h3 v-if="field.field_type == 'title'" class="title-underline">{{ field.field_name }}</h3>
                    <div v-else-if="field.field_type == 'description'" class="field-explication">{{ field.field_name }}</div>
                    <label v-else class="mb-2">{{ field.field_name }}</label>
                    <v-text-field v-if="field.field_type == 'text' || field.field_type == 'email' || field.field_type == 'number' || field.field_type == 'date' || field.field_type == 'tel' || field.field_type == 'url'"
                        :type="field.field_type"
                        outlined
                        dense
                        :placeholder="getConfig('placeholder')"
                        :required="getConfig('required', false)"
                        @change="updateField()"
                        v-model="theInputSimple"
                        :disabled="isDisabled()"
                    ></v-text-field>
                    <v-textarea v-if="field.field_type == 'textarea'"
                        outlined
                        dense
                        :placeholder="getConfig('placeholder')"
                        :required="getConfig('required', false)"
                        v-model="theInputSimple"
                        @change="updateField()"
                        :disabled="isDisabled()"
                    ></v-textarea>

                    <v-select
                        v-if="field.field_type == 'select'"
                        :items="getChoices()"
                        item-text="name"
                        item-value="val"
                        return-object
                        v-model="theInputSimple"
                        :placeholder="getConfig('placeholder')"
                        :required="getConfig('required', false)"
                        outlined
                        dense
                        attach
                        @change="updateFieldChoice()"
                        :disabled="isDisabled()"
                    ></v-select>
                    <div v-if="field.field_type == 'radio' && Object.keys(field.field_choices).length > 0">
                        <v-radio-group
                            @change="updateFieldChoice()"
                            :required="getConfig('required', false)"
                            v-model="theInputSimple"
                            :disabled="isDisabled()"
                        >
                            <v-radio v-for="choice in getChoices()" :key="choice.val"
                                :value="{val: choice.val, name: choice.name}"
                                :name="getInputName(choice.val)"
                                :label="choice.name"
                            ></v-radio>
                        </v-radio-group>
                    </div>
                    <div v-if="field.field_type == 'checkbox' && Object.keys(field.field_choices).length > 0">
                        <v-checkbox v-for="choice in getChoices()" :key="choice.val"
                            :value="{val: choice.val, name: choice.name}"
                            :name="getInputName(choice.val)"
                            :label="choice.name"
                            v-model="theInputMultiple"
                            @change="updateFieldMultiple()"
                            :disabled="isDisabled()"
                        ></v-checkbox>
                    </div>

                    <v-switch v-if="field.field_type == 'boolean'"
                            v-model="theInputSimple"
                            hide-details
                            @change="updateField()"
                            :disabled="isDisabled()"
                    ></v-switch>
                    <div v-if="field && field.field_type == 'file' || field.field_type == 'image'">
                        <File @addFiles="addFiles" />
                        <v-row>
                            <v-col cols="12" sm="6" md="6" lg="4" v-for="(file, index) in field.files" :key="index" class="mb-2">
                                <v-btn class="btn-file"><span class="btn-file-name" @click="retrieveFile(file.name)">{{ file.originalName }}</span><span class="btn-round btn-grey-black" @click="isStarted() ? deleteFile(index, file.name) : null"><i class="edt-close"></i></span></v-btn>
                            </v-col>
                        </v-row>
                    </div>
                </div>
                <div class="swipe-action swipe-right swipe">
                    <i class="edt-eye-slash"></i>
                </div>
            </div>
            <ItemActions v-if="modeEditionVisit == true && parseInt(currentStartedVisit) === parseInt(this.$route.params.id)" :label="field.field_name" :iterable="field.is_iterable" @updateLabel="updateLabel" @duplicateItem="duplicateItem" />
        </div>
        <v-row>
            <v-col cols="12" class="p-relative">
                <Comment :comment="this.fieldComment" @updateCommentField="updateCommentField" type="field" />
                <AlertFlag :alert="field.alert_flag" @updateAlertField="updateAlertField" />
            </v-col>
        </v-row>
    </div>
</template>

<script>
/*    import localForage from 'localforage';*/
    import dbService from '@/services/localDB';
    import { mapGetters } from "vuex";
    import File from '@/components/forms/File.vue';
    import Comment from '@/components/visit/detail/Comment.vue';
    import AlertFlag from '@/components/visit/detail/AlertFlag.vue';
    import ItemActions from "@/components/visit/detail/ItemActions";
    export default {
        name: 'FieldItem',
        props: {
            field: {
                type: Object,
                required: true
            }
        },
        computed: {
            ...mapGetters( 'visitStore', {currentStartedVisit: 'StateCurrentStartedVisit', modeEditionVisit: 'StateModeEditionVisit', seeHidden: 'StateSeeHidden'} ),
        },
        components: { /*Radio,*/ File, /*Select, Checkbox*/ Comment, AlertFlag, ItemActions },
        data () {
            return {
                theInputSimple: '',
                theInputMultiple: [],
                disabled: '',
                fieldComment: {
                    addComment: false,
                    comment: ''
                },
                fieldAlert: null,
            }
        },
        methods: {
            isStarted() {
                if(parseInt(this.currentStartedVisit) === parseInt(this.$route.params.id)) {
                    return true;
                }
                return false;
            },            
            getInputId(Id) {
                return this.field.field_type + '-item-' + Id;
            },
            getInputName(Id) {
                return this.field.field_type + Id;
            },
            retrieveFile(fileName) {                
                const db = dbService(this.$route.params.id); 
                db.getItem(fileName)
                    .then((file) => {
                        if (file) {
                            const url = URL.createObjectURL(file);
                            const link = document.createElement('a');
                            link.href = url;
                            link.download = file.name;
                            document.body.appendChild(link);
                            link.click();
                            URL.revokeObjectURL(url);
                        } else {
                            console.log('No file found in localForage.');
                        }
                    })
                    .catch((error) => {
                    console.error('Error retrieving file:', error);
                });
            },
            addFiles(files) {
                this.$emit("addFiles", this.field.id, files);
            },
            deleteFile(fileIndex, fileName) {
                this.$emit("deleteFile", this.field.id, fileIndex, fileName);
            },
            isDisabled() {
                if(parseInt(this.currentStartedVisit) === parseInt(this.$route.params.id)) {
                    return false;
                }
                return true;
            },
            getValue() {
                if(this.field.field_type == 'checkbox') {
                    if(this.field.field_value_id != "" && JSON.parse(this.field.field_value_id).length > 0) {
                        for(let i = 0; i < JSON.parse(this.field.field_value_id).length; i++) {
                            this.theInputMultiple.push({val: JSON.parse(this.field.field_value_id)[i], name: JSON.parse(this.field.field_value)[i]})
                        }
                    }
                } else if (this.field.field_type == 'radio' || this.field.field_type == 'select') {
                    if(this.field.field_value_id != "" && JSON.parse(this.field.field_value_id).length > 0) {
                        this.theInputSimple = {val: JSON.parse(this.field.field_value_id)[0], name: JSON.parse(this.field.field_value)[0]};
                    }
                } else {
                    if (this.field.field_value != "" && JSON.parse(this.field.field_value).length > 0) {
                        this.theInputSimple = JSON.parse(this.field.field_value)[0];
                    }
                }
            },
            getChoices() {
                let choices = [];
                let fieldChoices = this.field.field_choices;
                for (const [key, value] of Object.entries(fieldChoices)) {
                     choices.push({'val' : key, 'name' : value.field_choice_name});
                }
                return choices;
            },
            getConfig(conf, defaultValue = '') {
                let confValue = defaultValue;
                let fieldConfig = this.field.field_config;
                if(fieldConfig != null && fieldConfig != '' && Object.keys(JSON.parse(fieldConfig)).length > 0) {
                    if(conf in JSON.parse(fieldConfig)) {
                        confValue = JSON.parse(fieldConfig)[conf];
                    }
                }
                return confValue;
            },
            getVModel() {
                return 'input-' + this.field.id;
            },
            updateField() {
                this.$emit("updateJson", this.field.id, [this.theInputSimple]);
            },
            updateFieldChoice() {
                let fieldChoices = this.field.field_choices;
                this.$emit("updateJson", this.field.id, [this.theInputSimple.name], [this.theInputSimple.val], fieldChoices[this.theInputSimple.val].field_choice_note);
            },
            updateFieldMultiple() {
                if(this.theInputMultiple != null) {
                    this.theInputMultiple = this.theInputMultiple.filter(function (val) {
                        return val !== '' && val != null
                    });
                }
                let multipleId = [];
                let multipleName = [];

                this.theInputMultiple.forEach((answer) => {
                    multipleId.push(answer.val);
                    multipleName.push(answer.name);
                });
                /* todo : un select peut être multiple */
                this.$emit("updateJson", this.field.id, multipleName, multipleId);
            },
            checkVisibility() {
                let tmpClass = '';
                if (this.field.is_hidden === true)
                {
                    tmpClass = this.seeHidden === false ? 'd-none' : 'item-disabled';
                }
                return tmpClass;
            },
            hideItem() {
                if(this.modeEditionVisit === true && this.field.is_hidden !== true) {
                    this.$emit('visibilityField', { fieldId: this.field.id, hidden: true});
                }
            },
            showItem() {
                if(this.modeEditionVisit === true && this.field.is_hidden !== false) {
                    this.$emit('visibilityField', { fieldId: this.field.id, hidden: false});
                }
            },
            updateCommentField(itemComment) {
                this.fieldComment = itemComment;
                this.$emit("updateCommentField", { fieldId: this.field.id, comment: this.fieldComment});
            },
            updateAlertField(itemAlert) {
                this.$emit("updateAlertField", { fieldId: this.field.id, alert: itemAlert});
            },
            updateLabel(label) {
                if(this.modeEditionVisit === true) {
                    this.$emit('updateLabelField', { fieldId: this.field.id, label: label});
                }
            },
            duplicateItem(label) {
                if(this.modeEditionVisit === true) {
                    this.$emit('duplicateField', { fieldId: this.field.id, label: label});
                }
            },
        },
        created() {
                this.getValue();
                this.fieldComment.addComment = this.field.add_comment;
                this.fieldComment.comment = this.field.comment;
        }
    };
</script>
