<template>
    <div>
        <v-dialog
            v-model="dialogFiltres"
            max-width="600px"
        >
            <template v-slot:activator="{ on, attrs }">
                <div class="d-flex mb-3">
                    <div class="w-100">
                        <v-text-field
                            v-model="form.numberRequest"
                            class="pt-2"
                            :label="$t('echopark.list.filters.number')"
                            hide-details
                            @input="filter"
                        ></v-text-field>
                    </div>
                    <div class="flex-shrink-0 ml-3 px-2 pt-2">
                        <v-icon
                            v-bind="attrs"
                            v-on="on"
                        >mdi-filter</v-icon>
                    </div>
                </div>
            </template>
            <v-card>
                <v-card-title class="bg-grey-dark">
                    <span class="text-h5">{{ $t('echopark.list.filters.title') }}</span>
                </v-card-title>
                <v-card-text class="pt-3">
                    <v-row>
                        <v-col cols="12">
                            <v-select
                                :label="$t('echopark.list.filters.requestType')"
                                :items="filtres.requestType"
                                item-text="requestTypeName"
                                item-value="requestTypeId"
                                v-model="form.requestType"
                                attach
                            ></v-select>
                        </v-col>
                        <v-col cols="12">
                            <v-select
                                :label="$t('echopark.list.filters.stepType')"
                                v-model="form.stepType"
                                :items="filtres.stepTypeName"
                                item-text="typeName"
                                item-value="typeName"
                                attach
                            ></v-select>
                        </v-col>
                        <v-col cols="12">
                            <v-autocomplete
                                :label="$t('echopark.list.filters.realEstate')"
                                cols="12"
                                :items="filtres.realEstate"
                                item-text="realEstateName"
                                item-value="realEstateId"
                                v-model="form.realEstate"
                                attach
                            ></v-autocomplete>
                        </v-col>
                        <v-col cols="12">
                            <v-select
                                :label="$t('echopark.list.filters.urgency')"
                                v-model="form.urgency"
                                :items="filtres.urgencyName"
                                item-text="urgencyName"
                                item-value="urgencyName"
                                attach
                            ></v-select>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions class="justify-content-between">
                    <v-btn
                        @click="resetFilters"
                        class="button-submit-grey more-rounded"
                    >{{ $t("echopark.list.filters.reset") }}</v-btn>
                    <v-btn
                        @click="filter"
                        class="button-submit-orange more-rounded"
                    >{{ $t("echopark.list.filters.button") }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>

export default {
  name: "Filtres",
  props: ["filtres", "form"],
  components: {},
  data() {
    return {
        dialogFiltres: false,
    };
  },
  computed: {},
  methods: {
      filter() {
          this.$emit('filter');
          this.dialogFiltres = false;
      },
      resetFilters() {
          this.$emit('resetFilters');
          this.dialogFiltres = false;
      }
  },
};
</script>