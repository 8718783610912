<template>
    <v-app>
        <v-main :class="mainClass">
            <div class="app-content">
                <div class="d-none">
                    <v-icon></v-icon>
                    <v-radio></v-radio>
                </div>
                <div
                    v-if="this.$store.getters.hasMinVersion == true"
                    :class="containerClass"
                >
                    <router-view />
                </div>
                <div v-else class="text-center mt-5">
                    {{ $t("needUpdateApp") }}
                </div>
            </div>

            <NavBar v-if="showNavBarVisit && isLoggedIn && this.$store.getters.hasVisit" :menuItems="menuItemsVisit" />
            <NavBar v-else-if="showNavBarEP && isLoggedIn && this.$store.getters.hasEchopark" :menuItems="menuItemsOnlyEchoPark" />
            <NavBar v-else-if="showNavBar && isLoggedIn" :menuItems="menuItems" />
        </v-main>
    </v-app>
</template>

<script>
import NavBar from "@/components/NavBar";
import { mapActions } from "vuex";

export default {
  name: "App",
  components: { NavBar },
  data: () => ({
        value: "menu",
        menuItemsOnlyEchoPark : [
          {'value': 'home', 'color': '', 'url': 'Home', 'type': 'name', 'picto': 'edt-building', 'text': 'navbar.subtext.dashboardecho', 'nbAlert': false},
          {'value': 'listEchoPark', 'color': '', 'url': '/echopark-requests', 'type': 'link', 'picto': 'edt-intervention', 'text': 'navbar.subtext.echoparklist', 'nbAlert': false},
        ],
        menuItemsVisit : [
            {'value': 'home', 'color': '', 'url': 'Home', 'type': 'name', 'picto': 'edt-building', 'text': 'navbar.subtext.dashboard', 'nbAlert': false},
            {'value': 'DashboardVisit', 'color': '', 'url': 'DashboardVisit', 'type': 'name', 'picto': 'edt-dashboard', 'text': 'navbar.subtext.home', 'nbAlert': false},
            {'value': 'VisitList', 'color': '', 'url': 'VisitList', 'type': 'name', 'picto': 'edt-intervention', 'text': 'navbar.subtext.visits', 'nbAlert': false}
        ],
    }),
  computed: {
    menuItems() {
      let menu = [
        { value: 'home', color: '', url: 'Home', type: 'name', picto: 'edt-building', text: 'navbar.subtext.dashboard', nbAlert: false }
      ];

      if (this.$store.getters.hasIntervention) {
        menu.push({ value: 'listInterventions', color: '', url: '/requests?status=1', type: 'link', picto: 'edt-timer', text: 'navbar.subtext.interventions', nbAlert: false });
      }

      if (this.$store.getters.hasOS) {
        menu.push({ value: 'listOS', color: '', url: '/requests-os?status=1', type: 'link', picto: 'edt-intervention', text: 'navbar.subtext.os', nbAlert: false });
      }

      if (this.$store.getters.hasIntervention || this.$store.getters.hasOS) {
        menu.push({ value: 'planning', color: '', url: 'PlanningIndex', type: 'name', picto: 'edt-agenda', text: 'navbar.subtext.agenda', nbAlert: false });
        menu.push({ value: 'messaging', color: '', url: 'MessagingIndex', type: 'name', picto: 'edt-conversation', text: 'navbar.subtext.conversations', nbAlert: true });
      }

      return menu;
    },
    showTopBar() {
      return !this.$route.meta.hideTopBar && this.$store.getters.StateUserToken;
    },
    showNavBar() {
      return !this.$route.meta.hideNavBar && this.$store.getters.StateUserToken;
    },
    showNavBarVisit() {
      return this.$route.meta.showNavBarVisit && this.$store.getters.StateUserToken;
    },
    showNavBarEP() {
      return this.$route.meta.showNavBarEP && this.$store.getters.StateUserToken;
    },
    containerClass() {
      if (this.$route.meta.containerClass) {
        return this.$route.meta.containerClass;
      }
      return "";
    },
    mainClass() {
      if (this.$route.meta.mainClass) {
        return this.$route.meta.mainClass;
      }
      return "";
    },
    isLoggedIn: function () {
        return this.$store.getters.isAuthenticated;
    },
  },
  methods: {
    ...mapActions([
      "GetNotifications",
      "setNotificationTimer",
      "GetMessagingCount",
      "GetMinVersion",
      "InitMinVersion",
    ]),
    async intervalFunc() {
      this.GetMessagingCount();

      if (window.cordova) {
        this.GetMinVersion();
        var notifications = await this.GetNotifications();
        notifications.forEach((element) => {
          window.cordova.plugins.notification.local.schedule({
            title: "Eidetic",
            text: element.content,
            foreground: true,
          });
        });
      }
    },
    setTimerInterval(timer) {
      this.setNotificationTimer(timer);
    },
  },
  created() {
    this.InitMinVersion();
    if (this.$store.getters.StateUserToken) {
      this.intervalFunc();
      const timer = setInterval(this.intervalFunc, 120000);
      this.setTimerInterval(timer);
    }
  },
};
</script>